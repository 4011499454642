<template>
  <el-dialog
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :title="$t('docContent.notHelpful.submitFeedback')"
    width="640px"
    class="dialog-document-feedback"
    @close="onCloseDialog"
  >
    <el-form
      :model="formModel"
      :rules="formRules"
      label-width="100px"
      ref="form"
    >
      <el-form-item :label="$t('docContent.notHelpful.reason')" prop="questionCause">
        <el-select
          v-model="formModel.questionCause"
          :placeholder="$t('docContent.notHelpful.selectIssue')"
          style="width: 100%"
        >
          <el-option
            v-for="{ value, label } in questionCauseOptionList"
            :key="value"
            :label="label"
            :value="value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('docContent.notHelpful.description')" prop="questionDesc">
        <el-input
          v-model.trim="formModel.questionDesc"
          :rows="5"
          :maxlength="500"
          show-word-limit
          type="textarea"
          resize="none"
          :placeholder="$t('docContent.notHelpful.describleIssue')"
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onClickCancelButton">{{ $t('docContent.notHelpful.cancel') }}</el-button>
      <el-button
        :loading="confirmSubmitButtonLoading"
        type="primary"
        @click="onClickConfirmSubmitButton"
        >{{ $t('docContent.notHelpful.confirm') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import lcp from "@/api/extranet/lcp/index.ts";
import { UserModule } from '@/store';
import { debounce } from 'lodash';

export default {
  name: 'DialogDocumentFeedback',
  props: {
    dialogVisible: { type: Boolean, required: true },
    objId: { type: Number, required: true },
    docTypeMain: { type: Number, required: true },
    bizUnitId: { type: Number | String },
  },
  data() {
    return {
      formModel: { questionCause: undefined, questionDesc: '' },
      formRules: {
        questionCause: [{ required: true, message: this.$t('docContent.notHelpful.selectIssue') }],
        questionDesc: [
          { required: true, message: this.$t('docContent.notHelpful.describleIssue') },
        ],
      },
      questionCauseOptionList: [
        { label: this.$t('docContent.notHelpful.notDetail'), value: 1 },
        { label: this.$t('docContent.notHelpful.notTimely'), value: 2 },
        { label: this.$t('docContent.notHelpful.incorrect'), value: 3 },
        { label: this.$t('docContent.notHelpful.poorVisual'), value: 4 },
        { label: this.$t('docContent.notHelpful.other'), value: 5 },
      ],
      confirmSubmitButtonLoading: false,
    };
  },
  methods: {
    // 根据文档ID评价文档是否有帮助接口
    async submitQuestion() {
      const { objId, docTypeMain, bizUnitId } = this;
      const { questionCause, questionDesc } = this.formModel;
      const whetherHelp = 0;
      const createUser = UserModule.user_pin || undefined;
      const data = {
        objId,
        whetherHelp,
        docTypeMain,
        bizUnitId,
        questionCause,
        questionDesc,
        createUser,
      };
      return await lcp.evaluate.submitQuestion(data);
    },

    // 根据文档ID评价文档是否有帮助接口调用
    async handleSubmitQuestion() {
      await this.submitQuestion().catch((error) => {
        console.error('handleSubmitQuestion');
        throw error;
      });
    },

    onCloseDialog() {
      this.$refs.form.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    onClickCancelButton() {
      this.onCloseDialog();
    },
    onClickConfirmSubmitButton: debounce(async function () {
      await this.$refs.form.validate().catch(() => {
        throw new Error('未通过表单校验');
      });
      this.confirmSubmitButtonLoading = true;
      await this.handleSubmitQuestion().catch((error) => {
        this.confirmSubmitButtonLoading = false;
        console.error('onClickConfirmSubmitButton');
        throw error;
      });
      this.confirmSubmitButtonLoading = false;
      this.onCloseDialog();
      this.$emit('submit');
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
// .dialog-document-feedback {
// }
</style>
